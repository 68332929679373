import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '../auth/authService.js'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        redirect: true
      }
    },
    {
      path: '/jitsi-meeting/:room_id',
      name: 'jitsi_meeting',
      component: () => import('@/views/Admin/Meetings/JitsiMeeting.vue'),
      meta: {
          requiresAuth: false,
          role: [Vue.prototype.$permissions.GetHabitMeetings, Vue.prototype.$permissions.GetStaninvestMeetings]
      }
    },
    {
      path: '/collected_points',
      name: 'collected_points',
      component: () => import('@/views/Admin/CollectedPoints/CollectedPoints.vue'),
      meta: {
          requiresAuth: false,
          role: [Vue.prototype.$permissions.GetCollectedPoints]
      }
    },
    {
      path: '/nepremicnine',
      name: 'admin-real-estates-list',
      component: () => import('@/views/Admin/RealEstate/RealEstateList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetRealEstate]
      }
    },
    {
      path: '/e_sestanki',
      name: 'meetings',
      component: () => import('@/views/Admin/Meetings/Meetings.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetHabitMeetings, Vue.prototype.$permissions.GetStaninvestMeetings]
      }
    },
    {
      path: '/dodaj-nepremicnino',
      name: 'add-real-estate',
      component: () => import('@/views/Admin/RealEstate/AddRealEstate'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditRealEstate]
      }
    },
    {
      path: '/uredi-nepremicnino/:real_estate_id',
      name: 'edit-real-estate',
      component: () => import('@/views/Admin/RealEstate/EditRealEstate'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditRealEstate]
      }
    },
    {
      path: '/napremicninski-agenti',
      name: 'real-estate-agents',
      component: () => import('@/views/Admin/RealEstate/Agents/AgentList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetRealEstate]
      }
    },
    {
      path: '/novice',
      name: 'admin-news-list',
      component: () => import('@/views/Admin/News/NewsList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetNews]
      }
    },
    {
      path: '/faqs',
      name: 'faq-list',
      component: () => import('@/views/Admin/Faq/FaqList.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetFAQ]
      }
    },
    {
      path: '/dodaj/faq',
      name: 'faq-add',
      component: () => import('@/views/Admin/Faq/AddFaq.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditFAQ]

      }
    },
    {
      path: '/uredi/faq/:faq_id',
      name: 'faq-edit',
      component: () => import('@/views/Admin/Faq/EditFaq.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditFAQ]
      }
    },
    {
      path: '/o-nas',
      name: 'about-us',
      component: () => import('@/views/Admin/ContentEdit/AboutUs'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/pogoji-varovanja',
      name: 'conditions',
      component: () => import('@/views/Admin/ContentEdit/Conditions'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/pogoji-poslovanja',
      name: 'business-conditions',
      component: () => import('@/views/Admin/ContentEdit/BusinessConditions/BusinessConditionList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/dodaj/pogoje-poslovanja/:manager',
      name: 'business-condition-add',
      component: () => import('@/views/Admin/ContentEdit/BusinessConditions/AddBusinessCondition'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditPages]
      }
    },
    {
      path: '/uredi/pogoje-poslovanja/:id',
      name: 'business-condition-edit',
      component: () => import('@/views/Admin/ContentEdit/BusinessConditions/EditBusinessCondition'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditPages]
      }
    },
    {
      path: '/tocke-zvestobe',
      name: 'loyalty-points',
      component: () => import('@/views/Admin/ContentEdit/LoyaltyPoints'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/potek-nakupa',
      name: 'purchase-steps',
      component: () => import('@/views/Admin/ContentEdit/PurchaseSteps'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/nagrajevanje',
      name: 'awarding',
      component: () => import('@/views/Admin/ContentEdit/Awarding.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/piskotki',
      name: 'cookies',
      component: () => import('@/views/Admin/ContentEdit/Cookies'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/banner',
      name: 'banner',
      component: () => import('@/views/Admin/ContentEdit/Banner.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/neighborhoods',
      name: 'neighborhoods-list',
      component: () => import('@/views/Admin/Neighborhoods/NeighborhoodsList.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetRealEstate]
      }
    },
    {
      path: '/uredi/neighborhood/:neighborhood_id',
      name: 'neighborhood-edit',
      component: () => import('@/views/Admin/Neighborhoods/NeighborhoodEdit.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditRealEstate]
      }
    },
    {
      path: '/dodaj/neighborhood',
      name: 'neighborhood-add',
      component: () => import('@/views/Admin/Neighborhoods/NeighborhoodAdd.vue'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditRealEstate]
      }
    },
    {
      path: '/dodaj-novico',
      name: 'add-news',
      component: () => import('@/views/Admin/News/AddNews'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditNews]
      }
    },
    {
      path: '/uredi-novico/:news_id',
      name: 'edit-news',
      component: () => import('@/views/Admin/News/EditNews'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditNews]
      }
    },
    {
      path: '/storitve',
      name: 'admin-service-list',
      component: () => import('@/views/Admin/Service/ServiceList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetService]
      }
    },
    {
      path: '/dodaj-storitev',
      name: 'add-service',
      component: () => import('@/views/Admin/Service/AddService'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditService]
      }
    },
    {
      path: '/uredi-storitev/:service_id',
      name: 'edit-service',
      component: () => import('@/views/Admin/Service/EditService'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditService]
      }
    },
    {
      path: '/storitev-kuponi/:service_id',
      name: 'service-coupons',
      component: () => import('@/views/Admin/Service/ServiceCoupons'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditService]
      }
    },
    {
      path: '/ponudniki-storitev',
      name: 'service-providers',
      component: () => import('@/views/Admin/Service/Providers/ProviderList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetService]
      }
    },
    {
      path: '/oglasi',
      name: 'admin-classified-ad-list',
      component: () => import('@/views/Admin/ClassifiedAd/ClassifiedAdList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetClassifiedAd]
      }
    },
    {
      path: '/uredi/oglas/:ad_id',
      name: 'edit-classified-ad',
      component: () => import('@/views/Admin/ClassifiedAd/EditClassifiedAd'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditClassifiedAd]
      }
    },
    {
      path: '/kategorije',
      name: 'admin-category-list',
      component: () => import('@/views/Admin/Category/CategoryList'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/kontakti',
      name: 'contacts',
      component: () => import('@/views/Admin/ContentEdit/Contacts'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetPages]
      }
    },
    {
      path: '/trgovina/konfiguracija',
      name: 'webshop-configuration',
      component: () => import('@/views/Admin/WebShop/HomePageConfiguration'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetWebShop]
      }
    },
    {
      path: '/trgovina/promocije',
      name: 'webshop-promotions',
      component: () => import('@/views/Admin/WebShop/Promotions'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetWebShop]
      }
    },
    {
      path: '/administratorji',
      name: 'admin-list',
      component: () => import('@/views/Admin/Users/AdminList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetAdminUsers]
      }
    },
    {
      path: '/pravice',
      name: 'role-list',
      component: () => import('@/views/Admin/Users/Roles'),
      meta: {
        requiresAuth: true
      },
      role: [Vue.prototype.$permissions.GetRoles]
    },
    {
      path: '/uporabniki',
      name: 'user-list',
      component: () => import('@/views/Admin/Users/UserList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetHabitUsers, Vue.prototype.$permissions.GetStaninvestUsers]
      }
    },
    {
      path: '/oglasevanje',
      name: 'admin-advertisement-list',
      component: () => import('@/views/Admin/Advertisement/AdvertisementList'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dodaj-oglas',
      name: 'add-advertisement',
      component: () => import('@/views/Admin/Advertisement/AddAdvertisement'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/uredi-oglas/:advertisement_id',
      name: 'edit-advertisement',
      component: () => import('@/views/Admin/Advertisement/EditAdvertisement'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/trgovina/kategorije',
      name: 'webshop-category-list',
      component: () => import('@/views/Admin/WebShopCategories/WebShopCategoryList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetWebShop]

      }
    },
    {
      path: '/trgovina/obrocno-placilo',
      name: 'webshop-installment-payment',
      component: () => import('@/views/Admin/WebShop/InstallmentPayment'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetWebShop]
      }
    },
    {
      path: '/oglasna-deska',
      name: 'bulletin-board',
      component: () => import('@/views/Admin/BulletinBoard/BulletinBoardList'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.GetHabitBulletin, Vue.prototype.$permissions.GetStaninvestBulletin]
      }
    },
    {
      path: '/oglasna-deska/dodaj',
      name: 'add-bulletin',
      component: () => import('@/views/Admin/BulletinBoard/AddBulletin'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditHabitBulletin, Vue.prototype.$permissions.EditStaninvestBulletin]
      }
    },
    {
      path: '/oglasna-deska/uredi/:bulletin_id',
      name: 'edit-bulletin',
      component: () => import('@/views/Admin/BulletinBoard/EditBulletin'),
      meta: {
        requiresAuth: true,
        role: [Vue.prototype.$permissions.EditHabitBulletin, Vue.prototype.$permissions.EditStaninvestBulletin]
      }
    },
    {
      path: '/staninvest-upravnik-vlozisca',
      name: 'staninvest-upravnik-vlozisca',
      component: () => import('@/views/Admin/iiPortalVlozisca/iiPortalVlozisca'),
      meta: {
        requiresAuth: true,
        upravnik: 'staninvest',
        role: [Vue.prototype.$permissions.GetStaninvestVlozisce]
      }
    },
    {
      path: '/habit-upravnik-vlozisca',
      name: 'habit-upravnik-vlozisca',
      component: () => import('@/views/Admin/iiPortalVlozisca/iiPortalVlozisca'),
      meta: {
        requiresAuth: true,
        upravnik: 'habit',
        role: [Vue.prototype.$permissions.GetHabitVlozisce]
      }
    },
    {
      path: '/prijava',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {

  // pridobi loggedIn spremenljivko iz localStorage, ki jo ob loginu nastavimo na true
  const loggedIn = localStorage.getItem('loggedIn')
  if (loggedIn) {
    // ob reloadu strani (Ctrl + R) se vrednosti iz store (Vuex Store) pobrišejo
    // zato jih pridobimo iz localStorage in jih shranimo
    store.state.user.loggedIn = true
    const userData = localStorage.getItem('userData')
    if (userData) {
      store.state.user.userData = JSON.parse(userData)
    }
  }

  if (to.meta.redirect) {
    if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetRealEstate)) {
      return next('/nepremicnine')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetNews)) {
      return next('/novice')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetService)) {
      return next('/storitve')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetClassifiedAd)) {
      return next('/oglasi')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetAdminUsers)) {
      return next('/administratorji')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetHabitUsers) || Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetStaninvestUsers)) {
      return next('/uporabniki')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetRoles)) {
      return next('/pravice')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetWebShop)) {
      return next('/trgovina/kategorije')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetStaninvestBulletin) || Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetHabitBulletin)) {
      return next('/oglasna-deska')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetStaninvestMeetings) || Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetHabitMeetings)) {
      return next('/e_sestanki')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetCollectedPoints)) {
      return next('/collected_points')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetFAQ)) {
      return next('/faqs')
    } else if (Vue.prototype.$hasPermissions(Vue.prototype.$permissions.GetPages)) {
      return next('/kontakti')
    }
  }


  // če stran, na katero želimo iti vsebuje meta podatek requiresAuth
  // in iz authService zvemo, da user ni prijavljen, ga pošljemo na login
  if (to.meta.requiresAuth) {
    if (!authService.isAuthenticated()) return next('/prijava')
  }

  // Preverimo ce imamo pravice
  if (to.meta && to.meta.role && to.meta.role.length > 0) {
    let allow = false
    for (const permission of to.meta.role) {
       if (Vue.prototype.$hasPermissions(permission)) {
         allow = true
       }
    }

    if (!allow) {
      return next('/error-404')
    }
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
