import store from '@/store/index'
import Vue from 'vue'

const permissions = {
    GetRealEstate            :100,
    EditRealEstate           :101,
    GetNews                  :200,
    EditNews                 :201,
    GetService               :300,
    EditService              :301,
    EditServiceProvider      :302,
    GetClassifiedAd          :400,
    EditClassifiedAd         :401,
    EditClassifiedAdCategory :402,
    GetAdminUsers            :500,
    EditAdminUsers           :501,
    GetHabitUsers            :502,
    EditHabitUsers           :503,
    GetStaninvestUsers       :504,
    EditStaninvestUsers      :505,
    GetRoles                 :506,
    EditRoles                :507,
    LogInAsUserHabit         :508,
    LogInAsUserStaninvest    :509,
    GetWebShop               :600,
    EditWebShopCategory      :601,
    EditPromotion            :602,
    EditInstalmentPayment    :603,
    EditWebShopConfiguration :604,
    GetHabitBulletin         :700,
    EditHabitBulletin        :701,
    GetStaninvestBulletin    :702,
    EditStaninvestBulletin   :703,
    GetHabitMeetings         :800,
    EditHabitMeetings        :801,
    GetStaninvestMeetings    :802,
    EditStaninvestMeetings   :803,
    GetCollectedPoints       :900,
    EditCollectedPoints      :901,
    GetFAQ                   :1000,
    EditFAQ                  :1001,
    GetPages                 :1100,
    EditPages                :1101,
    GetHabitVlozisce         :1200,
    EditHabitVlozisce        :1201,
    GetStaninvestVlozisce    :1202,
    EditStaninvestVlozisce   :1203
}

function hasPermissions(permission) {
    if (!store.state.user || !store.state.user.userData || !store.state.user.userData.permissions || store.state.user.userData.permissions === 0) return false
    return store.state.user.userData.permissions.includes(permission)
}

Vue.prototype.$hasPermissions = hasPermissions
Vue.prototype.$permissions = permissions

export default permissions