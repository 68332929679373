import authService from '../../auth/authService'

const userDefaults = {
  uid: 0,
  displayName: '',
  about: '',
  photoURL: require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  status: 'online',
  userRole: '/'
}

export default {
  namespaced: true,
  state: {
    AppActiveUser: userDefaults,
    loggedIn: false,
    userData: null,
    managers: ['staninvest', 'habit', 'akustika group']
  },
  getters: {
    managers: state => {
      return state.managers
    }
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.loggedIn = true
    },
    SET_LOGGED_OUT(state) {
      state.loggedIn = false
    },
    UPDATE_USER_DATA(state, data) {
      state.userData = data
    }
  },
  actions: {
    login({ commit }, payload) {
      authService.setLoginParameters(payload)
      commit('SET_LOGGED_IN')
    },
    logout({ commit }) {
      authService.logOut()
      commit('SET_LOGGED_OUT')
    },
    setUserData({ commit }, payload) {
      commit('UPDATE_USER_DATA', payload)
      localStorage.setItem('userData', JSON.stringify(payload))

    }
  }
}
