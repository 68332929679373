export default {
  sl: {
    general: {
      upravnik_vlozisca: 'Vložišče upravnika',
      habit: 'Habit',
      staninvest: 'Staninvest',
      profile: 'Profil',
      home: 'Domov',
      second_page: 'Druga stran',
      statistics: 'Statistika',
      no: 'Ne',
      yes: 'Da',
      cancel: 'Prekini',
      UGODNOSTI: 'UGODNOSTI',
      furniture: 'Pohištvo',
      white_goods: 'Bela tehnika',
      home_accessories: 'Dodatki za dom',
      electronics: 'Elektronika',
      tools: 'Orodja',
      personal_care: 'Osebna nega',
      new: 'Novica',
      news: 'NOVICE',
      service: 'Storitev',
      services: 'STORITVE',
      services_small: 'Storitve',
      service_providers: 'Ponudniki storitev',
      E_SOSESKA: 'E-SOSESKA',
      e_soseska: 'E-soseska',
      add_ad: 'Dodaj oglas',
      item_page: 'izdelek',
      real_estates_small: 'Nepremičnine',
      agents: 'Agenti',
      real_estates: 'NEPREMIČNINE',
      MOJ_PORTAL: 'MOJ PORTAL',
      add_real_estate: 'Dodaj nepremičnino',
      ads: 'MALI OGLASI',
      ads_small: 'Mali oglasi',
      categories: 'Kategorije',
      users: 'UPORABNIKI',
      users_small: 'Navadni uporabniki',
      administrators: 'Administratorji',
      advertisement: 'OGLAŠEVANJE',
      bulletin_board: 'OGLASNA DESKA',
      e_meetings: 'E-SESTANKI',
      collected_points: 'TOČKE ZVESTOBE',
      neighborhoods: 'Soseske',
      shop: 'TRGOVINA',
      faqs: 'FAQ',
      installment_payment: 'Obročno plačilo',
      promotions: 'Promocije',
      webshop_configuration: 'Konfiguracija',
      contacts: 'Kontakti',
      text_edit: 'Urejanje vsebine',
      about_us: 'O nas',
      conditions: 'Pogoji varovanja',
      business_conditions: 'Pogoji poslovanja',
      loyalty_points: 'Točke zvestobe',
      purchase_steps: 'Potek nakupa',
      awarding: 'Nagrajevanje',
      cookies: 'Piškotki',
      news_highlight: 'Prikazna novica',
      banner: 'Banner',
      roles: 'Pravice'
    },
    login: {
      greeting: 'Dobrodošli!',
      login: 'Prijavi se',
      rememberMe: 'Zapomni si me',
      password: 'Geslo',
      forgottenPassword: 'Pozabljeno geslo?',
      logout: 'Odjavi se',
      wrong_password: 'Geslo je napačno!'
    },
    permissions: {
      get_real_estates: 'Ogled nepremičnin',
      edit_real_estates: 'Urejanje nepremičnin',
      get_neighborhood: 'Ogled sosesk',
      edit_neighborhood: 'Urejanje sosesk',
      get_agents: 'Ogled agentov',
      edit_agents: 'Urejanje agentov',
      get_news: 'Ogled novic',
      edit_news: 'Urejanje novic',
      get_service: 'Ogled storitev',
      edit_service: 'Urejanje storitev',
      get_service_provider: 'Ogled ponudnikov storitev',
      edit_service_provider: 'Urejanje ponudnikov storitev',
      get_classified_ads: 'Ogled malih oglasov',
      edit_classified_ads: 'Urejanje malih oglasov',
      get_classified_ad_category: 'Ogled kategorij malih oglasov',
      edit_classified_ad_category: 'Urejanje kategorij malih oglasov',
      get_admin_users: 'Ogled administratorjev',
      edit_admin_users: 'Urejanje administratorjev',
      get_habit_users: 'Ogled navadnih uporabnikov(habit)',
      edit_habit_users: 'Urejanje navadnih uporabnikov(habit)',
      get_staninvest_users: 'Ogled navadnih uporabnikov(staninvest)',
      edit_staninvest_users: 'Urejanje navadnih uporabnikov(staninvest)',
      get_roles: 'Ogled pravic',
      edit_roles: 'Urejanje pravic',
      log_in_as_user_habit: 'Prijavi se kot uporabnik(habit)',
      log_in_as_user_staninvest: 'Prijavi se kot uporabnik(staninvest)',
      get_web_shop: 'Ogled trgovine',
      edit_get_web_shop_category: 'Urejanje kategorij trgovine',
      edit_promotion: 'Urejanje promocij',
      edit_instalment_payment: 'Urejanje obročnih plačil',
      edit_web_shop_configuration: 'Urejanje konfiguracij trgovine',
      get_habit_bulletin: 'Ogled oglasne deske(habit)',
      edit_habit_bulletin: 'Urejanje oglasne deske(habit)',
      get_staninvest_bulletin: 'Ogled oglasne deske(staninvest)',
      edit_staninvest_bulletin: 'Urejanje oglasne deske(staninvest)',
      get_habit_meetings: 'Ogled e-sestanki(habit)',
      edit_habit_meetings: 'Urejanje e-sestanki(habit)',
      get_staininvest_meetings: 'Ogled e-sestanki(staninvest)',
      edit_staninvest_meetings: 'Urejanje e-sestanki(staninvest)',
      get_collected_points: 'Ogled točke zvestobe',
      edit_collected_points: 'Urejanje točke zvestobe',
      get_FAQ: 'Ogled FAQ',
      edit_FAQ: 'Urejanje FAQ',
      get_pages: 'Ogled vsebine',
      edit_pages: 'Urejanje vsebine',
      get_habit_vlozisce: 'Ogled vlozišča(habit)',
      edit_habit_vlozisce: 'Urejanje vlozišča(habit)',
      get_stainvest_vlozisce: 'Ogled vlozišča(staninvest)',
      edit_stainvest_vlozisce: 'Urejanje vlozišča(staninvest)'
    }

  },
  en: {
      general: {
        profile: 'Profile',
        home: 'Home',
        second_page: 'Second page',
        statistics: 'Statistics',
        no: 'No',
        yes: 'Yes',
        cancel: 'Cancel'
      }
    },
    login: {
      greeting: 'Welcome!',
      login: 'Login',
      rememberMe: 'Remember me',
      password: 'Password',
      forgottenPassword: 'Forgotten password?',
      logout: 'Log out',
      wrong_password: 'Password is not correct!'
    }
}
