import i18n from '@/i18n/i18n'

export default {
    namespaced: true,
    state: {
        permissions: {
            '100': i18n.t('permissions.get_real_estates'),
            '101': i18n.t('permissions.edit_real_estates'),
            '200': i18n.t('permissions.get_news'),
            '201': i18n.t('permissions.edit_news'),
            '300': i18n.t('permissions.get_service'),
            '301': i18n.t('permissions.edit_service'),
            '302': i18n.t('permissions.edit_service_provider'),
            '400': i18n.t('permissions.get_classified_ads'),
            '401': i18n.t('permissions.edit_classified_ads'),
            '402': i18n.t('permissions.edit_classified_ad_category'),
            '500': i18n.t('permissions.get_admin_users'),
            '501': i18n.t('permissions.edit_admin_users'),
            '502': i18n.t('permissions.get_habit_users'),
            '503': i18n.t('permissions.edit_habit_users'),
            '504': i18n.t('permissions.get_staninvest_users'),
            '505': i18n.t('permissions.edit_staninvest_users'),
            '506': i18n.t('permissions.get_roles'),
            '507': i18n.t('permissions.edit_roles'),
            '508': i18n.t('permissions.log_in_as_user_habit'),
            '509': i18n.t('permissions.log_in_as_user_staninvest'),
            '600': i18n.t('permissions.get_web_shop'),
            '601': i18n.t('permissions.edit_get_web_shop_category'),
            '602': i18n.t('permissions.edit_promotion'),
            '603': i18n.t('permissions.edit_instalment_payment'),
            '604': i18n.t('permissions.edit_web_shop_configuration'),
            '700': i18n.t('permissions.get_habit_bulletin'),
            '701': i18n.t('permissions.edit_habit_bulletin'),
            '702': i18n.t('permissions.get_staninvest_bulletin'),
            '703': i18n.t('permissions.edit_staninvest_bulletin'),
            '800': i18n.t('permissions.get_habit_meetings'),
            '801': i18n.t('permissions.edit_habit_meetings'),
            '802': i18n.t('permissions.get_staininvest_meetings'),
            '803': i18n.t('permissions.edit_staninvest_meetings'),
            '900': i18n.t('permissions.get_collected_points'),
            '901': i18n.t('permissions.edit_collected_points'),
            '1000': i18n.t('permissions.get_FAQ'),
            '1001': i18n.t('permissions.edit_FAQ'),
            '1100': i18n.t('permissions.get_pages'),
            '1101': i18n.t('permissions.edit_pages'),
            '1200': i18n.t('permissions.get_habit_vlozisce'),
            '1201': i18n.t('permissions.edit_habit_vlozisce'),
            '1202': i18n.t('permissions.get_stainvest_vlozisce'),
            '1203': i18n.t('permissions.edit_stainvest_vlozisce')
        }
    },
    getters: {
        getPermissions: state => {
            return state.permissions
        }
    }
}
