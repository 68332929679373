import jsonwebtoken from 'jsonwebtoken'
import store from '../store/index.js'

const loggedIn = 'loggedIn'
const tokenExpiryKey = 'tokenExpiry'
const jwtToken = 'accessToken'

const authService = {

  logOut() {
    localStorage.removeItem(loggedIn)
    localStorage.removeItem(tokenExpiryKey)
    localStorage.removeItem('userData')
    localStorage.removeItem(jwtToken)

    store.state.user.loggedIn = false
    store.state.user.userData = null
  },

  setLoginParameters(token) {
    localStorage.setItem(loggedIn, 'true')
    localStorage.setItem(jwtToken, token)
    store.state.user.loggedIn = true
  },
  getDecodedJWT() {
    const jwt = localStorage.getItem(jwtToken)
    const decoded = jsonwebtoken.decode(jwt)

    return decoded
  },

  isAdmin() {
    const jwt = localStorage.getItem(jwtToken)
    const decoded = jsonwebtoken.decode(jwt)
    return decoded && decoded.is_admin
  },

  isAuthenticated() {
    const jwt = localStorage.getItem(jwtToken)
    const decoded = jsonwebtoken.decode(jwt)

    const stillValid = jwt
    && new Date(Date.now()) < new Date(decoded.validUntil)
    && localStorage.getItem(loggedIn) === 'true'

    if (!stillValid) {
      // if token expired, log user out completely and delete all data
      this.logOut()
    }

    return stillValid
  }

}

export default authService
